/** @jsx jsx */
import { jsx, Box, Grid, Heading } from "theme-ui"
import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from 'gatsby-image'
import slugify from "@sindresorhus/slugify"

import SEO from "../components/seo"
import Layout from '../components/layout'

const AlpsPage = () => {
  let { allPrismicAlps } = useStaticQuery(graphql`
    query alpsQuery {
      allPrismicAlps {
        nodes {
          id
          data {
            alp_name {
              text
            }
            alp_image {
              fluid(maxWidth: 960, maxHeight: 600) {
                ...GatsbyPrismicImageFluid
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Home" />
      <Box variant="layout.wrapper.large">
        <Heading as="h1">Alps</Heading>
        <Grid columns={[1, 2, 2, 2, 3]} gap={4}>
          {allPrismicAlps.nodes.map(alp => {
            let name
            let slug
            let Image
            
            name = alp.data.alp_name.text
            slug = slugify(name)
            Image = props => <Img fluid={alp.data.alp_image.fluid} {...props} />
            
            return (
              <Link key={alp.id} sx={{ variant: "links.default" }} to={`/alps/${slug}`}>
                <Box sx={{ flex: "1 0 30%" }}>
                  <Image sx={{objectFit: "cover", width: "100%", height: ["14rem", "14rem", "16rem", "18rem"]}} />
                  <Heading as="h4">
                      {name}
                  </Heading>
                </Box>
              </Link>
            )
          })}
        </Grid>
      </Box>
    </Layout>
  )
}

export default AlpsPage
